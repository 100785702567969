/**
 * Tracks and increments ad placements for specific components within a namespace.
 *
 * @param {Object} ref - A React ref used to store placement data.
 * @returns {Function} A function to retrieve and increment a placement number for a given
 * namespace, component, and placement index.
 *
 * @example
 * const ref = { current: {} };
 * const getPlacementNumber = incrementAdPlacements(ref);
 *
 * console.log(getPlacementNumber('story', 1, 1)); // Outputs: 1
 * console.log(getPlacementNumber('story', 1, 2)); // Outputs: 2
 * console.log(getPlacementNumber('story', 2, 1)); // Outputs: 3
 * console.log(getPlacementNumber('story', 1, 2)); // Outputs: 2
 * console.log(getPlacementNumber('promo', 1, 1)); // Outputs: 1
 */
export const incrementAdPosition = (ref = { current: {} }) => (
  /**
   * Retrieves a position for a specific type, componentIndex, and adIndex. Increments the position
   * if it doesn't already exist.
   *
   * @param {string} [type=''] - The namespace for an ad type (e.g., "story").
   * @param {number} componentIndex - The index of the parent component in the hierarchy.
   * @param {number} adIndex - The index of the specific ad within the component.
   * @returns {number} The unique position number for the specific type, componentIndex, and
   * adIndex.
   */
  (type = '', componentIndex, adIndex) => {
    // Generate a unique key for this ad using the component and ad indices.
    const key = `${componentIndex}-${adIndex}`;

    // Initialize the namespace (type) if it doesn't exist.
    if (!ref.current[type]) {
      ref.current[type] = {
        count: 0, // Tracks the total number of unique ads within this ad type.
        ads: {}, // Stores the position for each unique ad.
      };
    }

    // Check if this specific ad already has a position defined.
    if (ref.current[type].ads[key] === undefined) {
      ref.current[type].count++; // Increment the global count for the ad type.

      // Assign the incremented count to this ad type.
      ref.current[type].ads[key] = ref.current[type].count;
    }

    // Return the unique position for this ad.
    return ref.current[type].ads[key];
  }
);
